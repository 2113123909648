import '/index.scss';

import SiteHeader from './ts/modules/SiteHeader';
import ReadMore from './ts/modules/ReadMore';
import WorksMasonry from  './ts/modules/WorksMasonry';
import CopyrightNotice from './ts/modules/CopyrightNotice';

//Carousel
import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

//lightbox
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

//Swup transitions
// import Swup from 'swup';
// import SwupBodyClassPlugin from '@swup/body-class-plugin';
// import SwupA11yPlugin from '@swup/a11y-plugin';
// import SwupHeadPlugin from '@swup/head-plugin';
// import SwupProgressPlugin from '@swup/progress-plugin';
// import SwupScrollPlugin from '@swup/scroll-plugin';

//Sentry
import * as Sentry from '@sentry/browser';

// run once when page loads
if (document.readyState === 'complete') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    init();
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      tunnel: '/sentry-tunnel',
      environment: import.meta.env.MODE
    });
  });
}

const siteHeader = new SiteHeader();
const copyrightNotice = new CopyrightNotice();

// const swup = new Swup({
//   containers: [
//     '#main',
//     '#site-logo',
//     '#main-menu',
//     '#site-footer'],
//   ignoreVisit: (url, { el } = {}) => {
//     return (
//       el?.closest('.glightbox') != null
//     );
//   },
//   //animateHistoryBrowsing: true,
//   plugins: [
//     new SwupBodyClassPlugin({
//       prefix: 'template-'
//     }),
//     new SwupA11yPlugin(),
//     new SwupHeadPlugin({
//       persistAssets: true //vite!
//     }),
//     //
//     new SwupProgressPlugin(),
//     new SwupScrollPlugin({
//       doScrollingRightAway: false,
//       animateScroll: {
//         betweenPages: true,
//         samePageWithHash: false,
//         samePage: false
//       }
//     })
//   ],
// });
// // Run after every additional navigation by swup
// swup.hooks.on('page:view', () => init());
// swup.hooks.on('animation:out:end', () => {
//   siteHeader.close();
// });


function init() {

  siteHeader.close();

  document.querySelectorAll('.swiper').forEach(element => {
    if(element instanceof HTMLElement) {
      
      const swiper = new Swiper(element, {

        keyboard: {
          enabled: true
        },
        lazyPreloadPrevNext: 3,
        modules: [Navigation, Pagination, Keyboard],
        loop: true,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 0,
        breakpoints: {
          '@0.8': {
            spaceBetween: 20,
          },
          '@1.1': {
            spaceBetween: 30,
          },
        },
      })
    }
  });

  GLightbox({
    zoomable: false,
    moreLength: 0, //0 = no limit on description
    height: '100vh',
    width: '100vw',
    svg: {
      close: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="90" viewBox="0 0 32 32" xml:space="preserve"><path stroke="currentColor" fill="none" stroke-linecap="round" d="M 2,2 L 30,30 M 30,2 L 2,30"/></svg>',
      next:  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="90" viewbox="0 0 32 32" xml:space="preserve"><path stroke="currentColor" fill="none" stroke-linecap="round" d="M 10,2 l 14 14 l -14 14"/></svg>',
      prev:  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="90" viewbox="0 0 32 32" xml:space="preserve" style="transform:scale(-1,1);"><path stroke="currentColor" fill="none" stroke-linecap="round" d="M 10,2 l 14 14 l -14 14"/></svg>',
    }
  });

  document.querySelectorAll('.read-more-wrapper').forEach((element) => {

    if(element instanceof HTMLElement) {
      new ReadMore(element);
    }
  })

  document.querySelectorAll('.works-masonry, .works-masonry-flex').forEach((element) => {

    if(element instanceof HTMLElement) {
      new WorksMasonry(element);
    }
  })
}

// function unload() {

//   swipers.forEach((element, key) => {
//     console.log('destroying swiper', element.swiper);
//     element.swiper.destroy();
//   })

//   lightbox.destroy();

//   readMores.forEach((value) => {
//     console.log('destroying readmore:', value);
//     value.destroy();
//   })

// }
