export default class ReadMore {

  FOLD_SELECTOR = '.read-more-fold';

  isOpen: boolean = false;
  height: number;

  wrapper: HTMLElement;
  fold: HTMLElement;
  button: HTMLButtonElement;
  image: HTMLImageElement;

  labelReadMore: string = 'Read more';
  labelReadLess: string = 'Read less';

  clickHandler: EventListener;
  loadHandler: EventListener;

  constructor(wrapper: HTMLElement) {

    this.wrapper = wrapper;
    
    const fold = this.wrapper.querySelector(this.FOLD_SELECTOR);
    if(fold instanceof HTMLElement) {
      this.fold = fold;
    } else {
      throw new Error("Fold element not found");
    }

    const button = this.wrapper.querySelector('button');

    if(button instanceof HTMLButtonElement) {
      this.button = button;
      this.labelReadMore = button.dataset.labelMore ?? this.labelReadMore;
      this.labelReadLess = button.dataset.labelLess ?? this.labelReadLess;
    } else {
      throw new Error("Button element not found");
    }

    const image = this.wrapper.querySelector('img');
    if(image instanceof HTMLImageElement) {
      this.image = image;
    }

    this.clickHandler = this.handleClickEvent.bind(this);
    this.loadHandler = this.handleLoadEvent.bind(this);

    this.updateScrollHeightVar();

    this.enable();
  }

  enable() {
    this.button.addEventListener('click', this.clickHandler, false);
    this.button.addEventListener('load', this.loadHandler, false);
  }

  destroy() {
    this.button.removeEventListener('click', this.clickHandler);
    this.button.removeEventListener('load', this.clickHandler);
  }

  handleClickEvent(e: MouseEvent) {
    this.toggle();
  };

  handleLoadEvent(e: Event) {
    this.updateScrollHeightVar();
  }

  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }

  toggle(newState: boolean = !this.isOpen) {
    this.updateScrollHeightVar();
    this.wrapper.classList.toggle('unfolded', newState);
    this.button.setAttribute('aria-expanded', String(newState));
    this.button.textContent = newState ? this.labelReadLess : this.labelReadMore;
    this.isOpen = newState;

    if(!newState) {

      const id = 'profilePhoto';
      const yOffset = -50; 
      const element = document.querySelector('.content');
      const y = element!.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});

      //document.querySelector('.content')?.scrollIntoView({ block: "start", behavior: 'auto'});

      //destroy after first unfold
      this.destroy();
    }
  }

  updateScrollHeightVar() {

    const lineHeight = 21;
    const maxLineCap = 15 * lineHeight;
    const minLineCap = 5 * lineHeight;

    let fullHeight = 0;
    let lineCap = 0

    for (let i = 0; i < this.fold.children.length; i++) {

      const element = this.fold.children[i] as HTMLElement;
      let marginTop = parseInt(window.getComputedStyle(element).getPropertyValue('margin-top'));
      let marginBottom = parseInt(window.getComputedStyle(element).getPropertyValue('margin-bottom'));

      let elementHeight =
        element.offsetHeight
        + marginTop
        + marginBottom;

      fullHeight += elementHeight;

      
      let potentialLineCap = lineCap + element.offsetHeight + marginTop;
      
      if(lineCap < minLineCap && potentialLineCap < maxLineCap) {
        lineCap = potentialLineCap;
      }

    }

    this.height = fullHeight;

    this.fold.setAttribute(
      'style',
      '--full-height: ' + this.height + 'px;--folded-height: ' + lineCap + 'px;');

    return this.height;
  }
}