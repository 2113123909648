import { lock as bodyScrollLock, unlock as bodyScrollUnlock } from 'tua-body-scroll-lock'

export default class SiteHeader {

  ANIMATION_DURATION = 250;

  buttonToggleMenu: HTMLElement|null;
  clickHandler: EventListener;
  resizeHandler: EventListener;

  constructor() {
    this.buttonToggleMenu = document.getElementById('button-toggle-menu');

    
    if (this.buttonToggleMenu) {
      this.clickHandler = this.handleClickEvent.bind(this);
      this.resizeHandler = this.handleResizeEvent.bind(this);
      
      this.enable();
    }
  }

  enable() {
    window.addEventListener('click', this.clickHandler, false);
    window.addEventListener('resize', this.resizeHandler, false);
  }

  destroy() {
    window.removeEventListener('click', this.clickHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }

  handleClickEvent(e: MouseEvent) {

    switch(e.target) {
      case this.buttonToggleMenu:
        let isOpen = document.body.classList.contains('menu-open');
        this.toggle(!isOpen);
        break;

      default:
        //close menu when current page is clicked
        if(
          e.target instanceof HTMLAnchorElement
          && e.target.getAttribute('aria-current')
        ) {
          this.close();
        }
        break;
    }
  }

  handleResizeEvent(e: Event) {
    this.close(false);
  }

  toggle(state: boolean) {
    if(!state) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    document.body.classList.add('menu-open');
    bodyScrollLock();
  }

  close(animate: boolean = true) {

    if(animate) {
      //hide after fadeout
      document.body.classList.add('menu-closing');
      setTimeout(() => {
        document.body.classList.remove(...['menu-open', 'menu-closing']);
      }, this.ANIMATION_DURATION);
    } else {
      document.body.classList.remove(...['menu-open', 'menu-closing']);
    }
    bodyScrollUnlock();
  }
};
