export default class CopyrightNotice {

  COPYRIGHT_NOTICE: string = 'Please note that reproduction and distribution of content of this website without written permission of copyright holder is prohibited. Thank you for understanding.';
  mousedownHandler: EventListener;

  constructor() {
    this.mousedownHandler = this.handleMouseDownEvent.bind(this);
    this.enable();
  }

  enable() {
    console.info(
      '%c' + this.COPYRIGHT_NOTICE,
      'color: red; font-size: 1.5rem;'
    );
    window.addEventListener('mousedown', this.mousedownHandler, false);
  }

  destroy() {
    window.removeEventListener('mousedown', this.mousedownHandler);
  }

  handleMouseDownEvent(e: MouseEvent) {

    if(e.button == 2 && e.target instanceof HTMLImageElement) {
      this.open();
    }
  }

  open() {
    alert(this.COPYRIGHT_NOTICE);
  }
};
