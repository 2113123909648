export default class WorksMasonry {

  wrapper: HTMLElement;

  resizeHandler: EventListener;
  clickHandler: EventListener;

  pictures: NodeListOf<HTMLPictureElement>;
  items: NodeListOf<HTMLElement>;

  controlsElement: HTMLElement|null; 
  displayStyle: 'sizes';

  resizeTimeout: ReturnType<typeof setTimeout>;
  resizeDelay: 500;

  constructor(wrapper: HTMLElement) {
    this.wrapper = wrapper;
    this.items = wrapper.querySelectorAll('figcaption');
    this.pictures = wrapper.querySelectorAll('picture');

    this.controlsElement = document.querySelector('.works-masonry-controls');

    this.clickHandler = this.handleClickEvent.bind(this);
    this.resizeHandler = this.handleResizeEvent.bind(this);
    this.enable();
  }

  enable() {
    this.update();
    this.controlsElement?.addEventListener('click', this.clickHandler, false);
    window.addEventListener('resize', this.resizeHandler, false);
  }

  destroy() {
    this.controlsElement?.removeEventListener('click', this.clickHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }

  update() {
    this.items.forEach((element) => {
      const isOverflown = (element.clientHeight < element.scrollHeight);
      element.classList.toggle('is-overflown', isOverflown);
    })
  }

  handleClickEvent(e: MouseEvent) {

    const target = e.target as HTMLElement;

    switch(target.id) {

      case "works-masonry-controls-grid":
      case "works-masonry-controls-flex":

        const showGrid = (target.id == 'works-masonry-controls-grid');
        const showFlex = (target.id == 'works-masonry-controls-flex');

        //switch buttons
        this.controlsElement?.querySelectorAll('button').forEach(button => button.classList.remove('active'));
        target.classList.add('active');

        //switch to correct sizes attribute for proper srcset goodness
        this.pictures.forEach((picture) => {
          var newSizesAttr = showFlex ? picture.getAttribute('data-sizes-flex') : picture.getAttribute('data-sizes-grid');
          picture.querySelectorAll('source').forEach(sourceOrImage => {
            sourceOrImage.setAttribute('sizes', String(newSizesAttr));
          })
        });

        //fadeout wrapper
        this.wrapper.classList.add('is-changing');

        setTimeout(() => {

          //switch content
          this.wrapper.classList.toggle('works-masonry-grid', showGrid);
          this.wrapper.classList.toggle('works-masonry-flex', showFlex);
          
          //recalculate ellipses
          this.update();

          //fade in
          this.wrapper.classList.remove('is-changing');

        }, 250);
        break;
    }
  }

  handleResizeEvent() {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.update();
    }, this.resizeDelay);
  }
}

